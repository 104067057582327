@font-face {
    font-family: RedHatDisplay_body;
    src: url('../fonts/RedHatDisplay-Regular.ttf') format('truetype');
  }
  
  @font-face {
    font-family: RedHatDisplay_bold;
    src: url('../fonts/RedHatDisplay-Bold.ttf') format('truetype');
  }
  
  @font-face {
    font-family: RedHatDisplay_medium;
    src: url('../fonts/RedHatDisplay-Medium.ttf') format('truetype');
  }
  
  @font-face {
    font-family: RedHatDisplay_semibold;
    src: url('../fonts/RedHatDisplay-SemiBold.ttf') format('truetype');
  }
  
  @font-face {
    font-family: RedHatDisplay_italic;
    src: url('../fonts/RedHatDisplay-Italic.ttf') format('truetype');
  }
  
  @font-face {
    font-family: 'Literata_regular';
    src: url('../fonts/Literata_18pt-Regular.ttf') format('truetype');
  }
  
  @font-face {
    font-family: 'Literata_medium';
    src: url('../fonts/Literata_18pt-Medium.ttf') format('truetype');
  }
  
  @font-face {
    font-family: 'Literata_semibold';
    src: url('../fonts/Literata_18pt-SemiBold.ttf') format('truetype');
  }
  
  @font-face {
    font-family: 'Literata_bold';
    src: url('../fonts/Literata_18pt-Bold.ttf') format('truetype');
  }

  @font-face {
    font-family: "Poppins_body";
    src: url('../fonts/Poppins-Regular.ttf') format('truetype');
  }

  @font-face {
    font-family: "Poppins_bold";
    src: url('../fonts/Poppins-Bold.ttf') format('truetype');
  }

  @font-face {
    font-family: "Poppins_semibold";
    src: url('../fonts/Poppins-SemiBold.ttf') format('truetype');
  }

  @font-face {
    font-family: "Poppins_medium";
    src: url('../fonts/Poppins-Medium.ttf') format('truetype');
  }

  .redhat_regular {
    font-family: 'RedHatDisplay_body', Helvetica, Arial, sans-serif !important;
    line-height: 1.5rem;
  }
  
  .redhat_medium {
    font-family: 'RedHatDisplay_medium', Helvetica, Arial, sans-serif !important;
  }
  
  .redhat_bold {
    font-family: 'RedHatDisplay_bold', Helvetica, Arial, sans-serif !important;
  }

  .poppins_bold {
    font-family: 'Poppins_bold', Helvetica, Arial, sans-serif !important;
  }
  
  
  .redhat_semibold {
    font-family: 'RedHatDisplay_semibold', Helvetica, Arial, sans-serif !important;
  }
  
  .redhat_italic {
    font-family: 'RedHatDisplay_italic', Helvetica, Arial, sans-serif !important;
  }
  
  .literata_regular {
    font-family: 'Literata_Regular', Georgia, Times, serif !important;
  }
  .literata_medium {
    font-family: 'Literata_medium', Georgia, Times, serif !important;
  }
  .literata_semibold {
    font-family: 'Literata_semibold', Georgia, Times, serif !important;
  }
  .literata_bold {
    font-family: 'Literata_bold', Georgia, Times, serif !important;
  }
  