// Base colors
$black: #000 !default;
$red: #ed0707 !default;
$white: #fff !default;
$mauve: #b3a9a3 !default;
$black: #000;
$neutral: #b3a8a2 !default;
$snow: #fcfafa !default;
$panache: #f5f0ee !default;

// Primary colors
$primary: #227066 !default;
$secondary: #333131 !default;
$primary-disabled: #a7c6c2 !default;
$font-primary: #171717 !default;
$btn-pressed-secondary: #4e8d85 !default;

// Additional colors
$background: #fff !default;
$btn-hover: #0d5b51 !default;
$dark-charcoal: #343132 !default;
$very-dark-grayish-red: #333131 !default;
$green-cream: #a9edce !default;
$light-green: #d3e2e0 !default;
$circle-family: #3b3ba8 !default;
$circle-track-family: #b4c4fc !default;
$gray15: #262626 !default;
$dark-grayish: #333131 !default;
$elm-green: #5d585a !default;
$sweet-cream: #eee7e4 !default;
$granite-gray: #696363 !default;
$very-light-gray: #ececec !default;
$lush-aqua: #0e406a !default;
$suva-grey: #8d8585 !default;
$abbey: #4b4849 !default;
$linkblue: #0e406a !default;
$lavender: #e1e1fe !default;
$focus-color: #0467eb;
$linkblue-focus: #0467eb;
$black700: #4b4849;

// Error colors
$error-text-color: #dc2626;
$global-error-text: #991b1b;
$global-error-border: #fecaca;
$global-error-bg: #fef2f2;